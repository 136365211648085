
import { RuntimeTemplateCompiler } from 'vue-runtime-template-compiler';
import { Objective } from '../api/src/models/Objective';

export default {
  name: 'ObjectiveView',
  components: {
    RuntimeTemplateCompiler,
  },
  props: {
    name: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    objective() {
      return this.name ? this.$store.getters['objectives/items'].find(i => i.name === this.name) : undefined;
    },
    sender() {
      let s = Objective.SenderName.enum.Ellie;

      if (this.objective && this.objective.sender) {
        s = this.objective.sender;
      }

      return Objective.SenderSetting[s];
    },
    closing() {
      return (this.objective && this.objective.closing)
        ? Objective.LetterClosing[this.objective.closing]
        : Objective.LetterClosing[Objective.LetterClosingName.enum.Yours];
    },
    descriptionComponent() {
      return {
        template: `<div>${this.objective.description.map(d => `<p>${d}</p>`).join('')}</div>`,
      };
    },
  },
  destroyed() {
    this.handleView();
  },
  methods: {
    handleView() {
      this.$store.dispatch('userSettings/editObjectives', [{
        name: this.objective.name,
        didView: true,
      }]);
    },
    handleViewAll() {
      this.$emit('close');
      this.$store.dispatch('ui/showModal', { name: 'objectivesList' });
    },
  },
};
